// **  Initial State
const initialState = {
    loggedIn: false,
    userData: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                loggedIn: true,
                userData: { ...action.data }
            }
        case 'LOGOUT':
            return {
                ...state,
                loggedIn: false,
                userData: null
            }
        default:
            return state
    }
}

export default authReducer
