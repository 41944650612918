import { Ability } from '@casl/ability'
import { store } from '@store/storeConfig/store'

const ability = new Ability([])

function defineRulesFor(user) {
    const abilities = []

    if (user.company.status === 2 && user.company.type === 1) {
        abilities.push(
            {
                action: 'read',
                subject: [
                    'Setup',
                    user.company.zzp ? 'ZZP' : 'null'
                ]
            }
        )
    } else if (user.permissions === 1) { // PERMISSION 1
        abilities.push(
            {
                action: ['read'],
                subject: user.company.type === 1 ? ['Default', 'Contractor'] : ['Default', 'Provider']
            }
        )
    } else if (user.permissions === 2) { // PERMISSION 2
        abilities.push(
            {
                action: ['read', 'update'],
                subject: user.company.type === 1 ? ['Default', 'Contractor'] : ['Default', 'Provider']
            }
        )
    } else if (user.permissions === 3) { // PERMISSION 3
        abilities.push(
            {
                action: ['manage'],
                subject: user.company.type === 1 ? ['Default', 'Contractor'] : ['Default', 'Provider']
            }
        )
    } else if (user.permissions === 4) { // PERMISSION 4 - DEVELOPMENT ONLY
        abilities.push(
            {
                action: ['manage'],
                subject: 'all'
            }
        )
    }

    if (user.company.subscription === 1) {
        abilities.push(
            {
                action: ['read'],
                subject: 'basic'
            }
        )
    } else if (user.company.subscription === 2) {
        abilities.push(
            {
                action: ['read'],
                subject: ['basic', 'standard']
            }
        )
    } else if (user.company.subscription === 3) {
        abilities.push(
            {
                action: ['read'],
                subject: ['basic', 'standard', 'premium']
            }
        )
    }

    return abilities
}

let currentUser;
store.subscribe(() => {
    const prevUser = currentUser;
    currentUser = store.getState().auth.userData;
    if (currentUser && prevUser !== currentUser) {
        ability.update(defineRulesFor(currentUser));
    }
});

export default ability
