import axios from "axios"
import { history } from '@utils'
import { toast } from "react-toastify"

const setUser = (data) => ({
    type: "SET_USER",
    data
})

// ** Handle User Login
export const handleLogin = (user) => {

    return dispatch => {
        axios.post("/api/auth/login", {
            email: user.email,
            password: user.password
        }).then(res => {
            const token = res.data.access_token
            const user = res.data.user

            localStorage.setItem("token", token)
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

            dispatch(setUser(user))
            if (user.company.status === 2 && user.company.type === 1) {
                history.push("/setup")
            } else {
                history.push("/home")
            }
        }).catch(() => {
            toast.error("Uw inloggegevens zijn onjuist!") // error checks..
        })
    }
}

// ** Handle User Logout
export const handleLogout = () => {
    return dispatch => {
        dispatch({ type: 'LOGOUT' })
        localStorage.clear()
        history.push('/login')
    }
}

// ** Fetch User
export const fetchUser = () => {
    const pth = history.location.pathname
    const urls = ['/register', '/verify', '/reset-wachtwoord']

    return dispatch => {
        if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`
            axios.get("/api/auth/me")
                .then(res => {
                    dispatch(setUser(res.data.user))
                    if (pth === '/login' || pth === '/register' || pth === '/verify') {
                        history.push("/home")
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            handleLogout()
                        }
                    }
                })
        } else {
            if ((pth === "/verify" || pth === "/reset-wachtwoord") && !history.location.search) {
                history.push("/login")
            }

            if (urls.indexOf(pth) === -1) {
                history.push("/login")
            }
        }
    }
}
