import axios from "axios"
import { connect } from "react-redux"
import { handleLogout } from "@src/redux/actions/auth"
const baseURL = process.env.REACT_APP_PUBLIC_PATH ? process.env.REACT_APP_PUBLIC_PATH : "https://api.corpomatch.nl/"
axios.defaults.baseURL = baseURL

//interceptors
function AxiosHandler(props) {
    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            const originalRequest = error.config
            const refreshToken = localStorage.getItem("token")

            if (
                error.response &&
                error.response.status === 401 &&
                error.response.data.message === "Refresh Token Expired"
            ) {
                // Refresh token expired, log user out
                props.handleLogout()
                return
            }

            if (
                error.response &&
                error.response.status === 401 &&
                error.config &&
                !error.config.__isRetryRequest &&
                refreshToken
            ) {
                originalRequest._retry = true
                return axios.post(`${baseURL}api/auth/refresh`, {
                    refresh: refreshToken,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${refreshToken}`
                    }
                }).then((res) => {
                    localStorage.setItem("token", res.data.token)
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${res.data.token}`
                    originalRequest.headers.Authorization = `Bearer ${res.data.token}`
                    return axios(originalRequest)
                })
            } else if (error.response && error.response.status === 429) {
                console.log("Too Many Requests")
                props.handleLogout() // voor nu
            } else if (
                error.response &&
                error.response.status === 500 &&
                (
                    error.response.data.message === "The token has been blacklisted" ||
                    error.response.data.message === "Token has expired and can no longer be refreshed" ||
                    error.response.data.message === "Token Signature could not be verified." ||
                    error.response.data.message === "Token not provided"
                )
            ) {
                props.handleLogout()
            } else if (error.response && error.response.status === 500) {
                console.log(error.response.data)
            }

            return Promise.reject(error)
        }
    )
    return null
}

export default connect(null, { handleLogout })(AxiosHandler)
// export default AxiosHandler
